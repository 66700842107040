.contact {
    width: 100%;
    height: 90vh;
    display: flex;
  }

.contactus_banner {
    position: relative;
    flex: 50%;
    height: 100%;
    background-image: url('../Media/ProjectsBG.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .contactus_banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(16, 143, 216, 1), rgba(76, 187, 252, 0.2)); /* Gradient fade from left to right */
    opacity: 0.8; /* Adjust the opacity as needed */
  }
  
  .contactus_banner-text {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    text-align: center;
    color: white;
    font-size: 60px;
    font-weight: 500;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}


  .contact .rightSide {
    height: 100%;
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  form {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    padding: 30px;
  }
  
  form input {
    height: 40px;
    width: 80%;
    border: none;
    border-bottom: 1px solid #121619;
    color: black;
  }
  
  form textarea {
    margin-top: 15px;
    height: 70px;
    width: 80%;
    border: none;
    border-bottom: 1px solid #121619;
    color: black;
    resize: none;
  }
  input::placeholder,
  textarea::placeholder {
    /* font-weight: bold; */
    color: rgb(164, 164, 164);
    font-family: Arial, Helvetica, sans-serif;
  }
  form input:focus,
  textarea:focus {
    outline: none;
  }
  
  form label {
    margin-top: 15px;
    color: black;
  }
  
  form button {
    margin-top: 40px;
    width: 140px;
    height: 40px;
    border: none;
    background-color: #121619;
    color: whitesmoke;
    font-size: 15px;
    cursor: pointer;
  }

  @media only screen and (max-width: 500px) {
    .contactus_banner-text {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      text-align: center;
      color: white;
      font-size: 30px;
      font-weight: 500;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  }