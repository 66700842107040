.footer {
    width: 100%;
    height: 100px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
  }
  
  .socials svg {
    color: #108FD8;
    margin: 20px;
    font-size: 50px;
    cursor: pointer;
  }
  
  .footer p {
    color: #108FD8;
  }