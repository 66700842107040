.home {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
  .home .headerContainer {
    width: auto;
    margin-left: 50px;
  }
  
  .home .headerContainer h1 {
    justify-content: center;
    align-items: center;
    font-size: 60px;
    height: 100px;
    font-weight: 50;
    color: white;
  }
  
  .home .headerContainer p {
    padding: 0;
    font-size: 30px;
    font-weight: lighter;
    color: white;
  }
  
    .DonateButton {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    background-color: white;
    height: 50px;
    width: 180px;
    color: #121619;
    text-decoration: none;
    font-size: 20px;
  }
  
   .DonateButton:hover {
    background-color: rgb(225, 225, 225);;
    cursor: pointer;
    transition: 0.3s ease-in;
  }

  .homediv{
    margin: 0;
    background-color: #CAE0FF;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction:column;
    padding-bottom: 40px;
  }

  .homediv .EBW{
    /* padding-top: 40px; */
    text-align: center;
    color: white;
    font-size: 35px; /*50*/
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-weight: 50;
    padding-left: 50px;
    padding-right: 50px;
  }

  .homediv .EBWP{
    color: white;
    /* padding-bottom: 10px; */
    font-size: 15px;  /*20*/
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-weight: lighter;
    padding-left: 50px;
    padding-right: 50px;
  }
  .ProjectsButton{
    display: inline-block;
    margin-left: 35px;
    margin-right: 35px;
    font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    border: none;
    border-radius: 25px;
    background: linear-gradient(45deg, #108FD8, #0B5C8F);
    padding: 10px;
    color: white;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s, transform 0.2s, box-shadow 0.3s;
    width: 200px;
    overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
  }

  .ProjectsButton:hover {
    background: linear-gradient(45deg, #0B5C8F, #108FD8);
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  @media only screen and (max-width: 475px) {
    .home {
      justify-content: center;
      align-items: center;
    }
  
    .home .headerContainer {
      margin-left: 0px;
      border-radius: 10px;
      padding: 10px;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: left;
      flex-direction: column;
      background-color: transparent;
      color: white;
    }
  
    .home .headerContainer h1 {
      font-size: 40px;
      height: 30px;
      color: white;
    }
  
    .home .headerContainer p {
      font-size: 20px;
      color: white;
      padding-top: 40px;
    }
  
    .home .headerContainer button {
      background-color: white;
      color: #121619;
      margin-bottom: 30px;
    }
  
    .home .headerContainer button:hover {
      background-color: rgb(225, 225, 225);
      color: #121619;
    }

    
  }

  @media only screen and (max-width: 351px) { 
    .home {
      justify-content: center;
      align-items: center;
    }
  
    .home .headerContainer {
      margin-left: 0px;
      border-radius: 10px;
      padding: 10px;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: left;
      flex-direction: column;
      background-color: transparent;
      color: white;
    }
  
    .home .headerContainer h1 {
      font-size: 30px;
      height: 30px;
      color: white;
    }
  
    .home .headerContainer p {
      font-size: 15px;
      color: white;
      padding-top: 40px;
    }
  
    .home .headerContainer button {
      background-color: white;
      color: #121619;
      margin-bottom: 30px;
    }
  
    .home .headerContainer button:hover {
      background-color: rgb(225, 225, 225);
      color: #121619;
    }
  }