
.navigationbar {
  background-color: white;
  height: 100px;
  display: flex;
  font-size: 1.2rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
  padding-top: 15px;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #CAE0FF;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.menu-icon {
  display: none;
}

.navigationbar img{
  padding-left: 30px;
  height: 100px;
}

@media screen and (max-width: 960px) {

  .navigationbar {
    background: white;
    display: flex;
    align-items: none;
    font-size: 1.2rem;
  }

  .nav-menu {
    display: flex;
    visibility: hidden;
    flex-direction: column;
    position: absolute;
    width: 50%;
    top: -100%;
    right: 0;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    box-sizing: border-box;
    display: flex;
    visibility: visible;
    flex-direction: column;
    width: 50%;
    position: absolute;
    background: white;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    top: 100px;
    /* left: 0; */
    /* right: 0; */
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

  }

  .nav-links {
    background-color: white;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    padding: 2rem;
    display: table;
    color: black;
  }

  .nav-links:hover {
    background-color:#CAE0FF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 100%);
    cursor: pointer;
    z-index: 99;
  }

  .menu-icon button {
    align-self: right;
    color: black;
    background-color: transparent;
    border-color: transparent;
    top: 0;
    right: 0;
    z-index: 99;
  }

  .navigationbar img{
    padding-left: 30px;
    height: 100px;
    z-index: 99;
  }

}