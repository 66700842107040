.joinus {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
}

.banner {
  position: relative;
  width: 100%;
  height: 190px; /* Adjust the height as needed */
  background-image: url('../Media/ProjectsBG.jpg');
  background-size: cover;
  background-position: center;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(16, 143, 216, 1), rgba(76, 187, 252, 0.2)); /* Gradient fade from left to right */
  opacity: 0.8; /* Adjust the opacity as needed */
}

.joinus .banner-text {
  position: absolute;
  margin-left: 50px;
  top: 50%;
  font-size: 60px;
  left: 20px;
  transform: translateY(-50%);
  color: white;
  font-weight: 50;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  
}
  
  .joinus .headerContainer p {
    padding: 0;
    font-size: 30px;
    font-weight: lighter;
    color: white;
  }
  
  .joinus .headerContainer button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    background-color: white;
    height: 50px;
    width: 180px;
    color: #121619;
    text-decoration: none;
    font-size: 20px;
  }
  
  .joinus .headerContainer button:hover {
    background-color: rgb(225, 225, 225);
    cursor: pointer;
    transition: 0.3s ease-in;
  }

  .joinus .contactInfo {
    place-content: center;
    width: 67%;
    color: white;
    border-radius: 15px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
    background-color: #EAEAEA;
    place-self: center;
    /* min-width: 50%; */
    text-align: center;
  }

  .joinus .contactInfo h1 {
    background-color: rgba(0, 0, 0, .1);
    font-size: 40px;
    font-weight: lighter;
    color: white;
    border-radius: 15px;
  }

  .joinus .contactInfo p {
    font-size: 20px;
    color: white;
    font-weight: lighter;
    min-width: 500px;
  }

  .joinus .contactInfo a {
    background-color: white;
    color: #121619;
    margin-bottom: 30px;
    border-radius: 5px;
  }

  .joinus .contactInfo a:hover {
    background-color: rgb(225, 225, 225);
    color: #121619;
  }
  
  .joinus .CurrentProjects {
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 50px;
    /* padding-left: 50px; */
    /* width: 100%; */
    text-emphasis-style: open;
    text-emphasis-color: rgba(16, 143, 216, 1);
  }

  .joinus .CurrentProjects p {
    font-size: 40px;
    color: black;
    font-weight: lighter;
    min-width: 500px;
    padding-left: 33%;
  }

  .joinus .projectsList {
    /* width: 70vw; */
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    place-items: center;
    margin-bottom: 30px;
  }

  /* MENU ITEM STYLING */
  
  .joinus .projectItem {
    border-radius: 15px;
    width: 220px; /*220*/
    height: 380px; /*300*/
    margin: 20px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  }

  .joinus .projectItem:hover {
    /* box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5); */
    transition: 0.05s ease-in;
    cursor: pointer;
  }
  
  .joinus .projectItem .image {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .joinus .projectItem:hover > .image { /* when hovering anywhere over projectItem, apply below css to just projectItem div */
    box-shadow: inset 0 0 0 150px rgba(16, 143, 216, 0.8);
    font-size: 20px;
  }

  .joinus .projectItem .divText {
    font-size: 18px;
    color: transparent;
  }

  .joinus .projectItem:hover .divText {
    font-size: 25px;
    color: white;
    font-weight: normal;
    padding-top: 87.5px;
    text-align: center;
  }

  .joinus .projectItem h1 {
    margin-left: 20px;
    text-decoration: none;
    color: black;
    font-size: 25px;
  }

  .joinus .projectItem p {
    margin: 20px;
    text-decoration: none;
    color: rgb(87, 87, 87);
    font-size: 16px;
    }

  .projectLink {
    text-decoration: none;
  } 

  .button-display{
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }

  .aesthetic-button {
    display: inline-block;
    margin-left: 35px;
    margin-right: 35px;
    font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    border: none;
    border-radius: 25px;
    background: linear-gradient(45deg, #108FD8, #0B5C8F);
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s, transform 0.2s, box-shadow 0.3s;
    width: 200px;
    overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
  }
  
  .aesthetic-button:hover {
    background: linear-gradient(45deg, #0B5C8F, #108FD8);
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  .aesthetic-button:active {
    transform: scale(0.95);
  }
 
  @media only screen and (max-width: 555px) {

    .aesthetic-button {
      display: inline-block;
      margin-left: 25px;
      margin-right: 25px;
      font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
      /* padding: 10px 40px; */
      border: none;
      border-radius: 25px;
      background: linear-gradient(45deg, #108FD8, #0B5C8F);
      padding-top: 20px;
      padding-bottom: 20px;
      color: white;
      font-size: 15px;
      font-weight: normal;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: background 0.3s, transform 0.2s, box-shadow 0.3s;
      width: 150px;
      overflow-wrap: break-word;  
      word-wrap: break-word; 
      word-break: break-word;
    }
  }

  @media only screen and (max-width: 410px) { 

    .joinus .banner-text {
      position: absolute;
      margin-left: 50px;
      top: 50%;
      font-size: 50px;
      left: 20px;
      transform: translateY(-50%);
      color: white;
      font-weight: 50;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
      
    }

    .aesthetic-button {
      display: inline-block;
      margin-left: 20px;
      margin-right: 20px;
      font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
      border: none;
      border-radius: 25px;
      background: linear-gradient(45deg, #108FD8, #0B5C8F);
      color: white;
      font-size: 15px;
      font-weight: normal;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: background 0.3s, transform 0.2s, box-shadow 0.3s;
      width: 150px;
      overflow-wrap: break-word;  
      word-wrap: break-word; 
      word-break: break-word;
    }

    .joinus .projectItem {
      border-radius: 15px;
      width: 165px;
      height: 285px;
      margin: 15px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    }
  
    .joinus .projectItem:hover {
      transition: 0.05s ease-in;
      cursor: pointer;
    }
    
    .joinus .projectItem .image {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      width: 100%;
      height: 127.5px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  
    .joinus .projectItem:hover > .image { 
      box-shadow: inset 0 0 0 150px rgba(16, 143, 216, 0.8);
      font-size: 20px;
    }
  
    .joinus .projectItem .divText {
      font-size: 18px;
      color: transparent;
    }
  
    .joinus .projectItem:hover .divText {
      font-size: 18.75px;
      color: white;
      font-weight: normal;
      padding-top: 54.375px;
      text-align: center;
    }
    
    .joinus .projectItem h1 {
      margin-left: 18.75px;
      text-decoration: none;
      color: black;
      font-size: 18.75px;
    }
  
    .joinus .projectItem p {
      margin: 18.75px;
      text-decoration: none;
      color: rgb(87, 87, 87);
      font-size: 12px;
    }

    .joinus .CurrentProjects {
      padding-top: 30px;
      padding-bottom: 10px;
      font-size: 40px;
      /* padding-left: 50px; */
      /* width: 100%; */
      text-emphasis-style: open;
      text-emphasis-color: rgba(16, 143, 216, 1);
    }

  }

  @media only screen and (max-width: 365px){

    .joinus .CurrentProjects {
      padding-top: 30px;
      padding-bottom: 10px;
      font-size: 40px;
      /* padding-left: 50px; */
      /* width: 100%; */
      text-emphasis-style: open;
      text-emphasis-color: rgba(16, 143, 216, 1);
    }

  }
  
  @media only screen and (max-width: 1000px) {
    .joinus .projectsList {
      grid-template-columns: 1fr 1fr;
    }

    .joinus .LookingForMembers {
      margin-left: 100px; 
      margin-right: 100px;
    }
  }

  @media only screen and (max-width: 600px) {
    .joinus .projectsList {
      grid-template-columns: 1fr 1fr;
    }

    .joinus .LookingForMembers {
      margin-left: 50px; 
      margin-right: 50px;
    }


  }

  @media only screen and (max-width: 700px) {
    .joinus .projectsList {
      grid-template-columns: 1fr;
    }
  }


  @media only screen and (max-width: 410px) {
    .joinus .projectsList {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (max-width: 389px) {
    .joinus .projectsList {
      grid-template-columns: 1fr;
    }

    .aesthetic-button {
      display: inline-block;
      margin-left: 20px;
      margin-right: 20px;
      font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
      border: none;
      border-radius: 25px;
      background: linear-gradient(45deg, #108FD8, #0B5C8F);
      color: white;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: background 0.3s, transform 0.2s, box-shadow 0.3s;
      width: 120px;
      overflow-wrap: break-word;  
      word-wrap: break-word; 
      word-break: break-word;
    }
  }