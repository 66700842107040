 .projects {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  }


  .projectsTitleSection {
    position: relative;
    width: 100%;
    height: 190px;
    background-image: url('../Media/ProjectsBG.jpg');
    background-size: cover;
    background-position: center;
  }

  .projectsOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(16, 143, 216, 1), rgba(76, 187, 252, 0.2));
    opacity: 0.8;
  }
  
  .projectsTitle {
    position: absolute;
    margin-left: 50px;
    top: 50%;
    font-size: 60px;
    left: 20px;
    transform: translateY(-50%);
    color: white;
    font-weight: 50;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    
  }

  /*testestestestest*/

  /* .projects .projectsTitle {
    font-size: 60px;
    width: 100%;
    color: white;
    height: 100px;
    padding-top:50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: top;
    align-items: top;
  } */
  
  .projects .projectsList {
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }
  .projects .subtitle{
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
  }

  .projects .CurrentProjects {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 50px;
    text-emphasis-style: open;
    text-emphasis-color: rgba(16, 143, 216, 1);
  }

 .projects .OldProjects {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 50px;
    text-emphasis-style: open;
    text-emphasis-color: rgba(16, 143, 216, 1);
  }
  
  .projects .projectItem {
    border-radius: 15px;
    width: 300px;
    height: 350px;
    margin: 15px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    background-color: white;
  }

  .projects .projectItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.05s ease-in;
    cursor: pointer;
  }
  
  .projects .projectItem div {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 170px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  


  .projects .projectItem h1 {
    margin-left: 20px;
    text-decoration: none;
    color: black;
    font-size: 25px;
  }
  .projects .projectItem p {
    margin: 20px;
    text-decoration: none;
    color: rgb(87, 87, 87);
  }

  .projectLink {
    text-decoration: none;
  }
  
  @media only screen and (max-width: 1200px) {
    .projects .projectsList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projects .projectsList {
      grid-template-columns: 1fr;
    }
  }

  @media only screen and (max-width: 410px) {

    .projectsTitle {
      position: absolute;
      margin-left: 25px;
      top: 50%;
      font-size: 50px;
      left: 20px;
      transform: translateY(-50%);
      color: white;
      font-weight: 50;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
      
    }

    .projects .CurrentProjects {
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 40px;
      text-emphasis-style: open;
      text-emphasis-color: rgba(16, 143, 216, 1);
    }
  
   .projects .OldProjects {
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 40px;
      text-emphasis-style: open;
      text-emphasis-color: rgba(16, 143, 216, 1);
    }

    .projects .projectItem div {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      width: 100%;
      height: 127.5px; /*85*/
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .projects .projectItem {
      border-radius: 15px;
      width: 165px; /*150*/
      height: 285px; /*175*/
      margin: 15px; /*20*/
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
      background-color: white;
    }

    .projects .projectItem h1 {
      margin-left: 18.75px;
      text-decoration: none;
      color: black;
      font-size: 18.75px;
    }
    .projects .projectItem p {
      margin: 18.75px;
      text-decoration: none;
      color: rgb(87, 87, 87);
      font-size: 12px;
    }


    .projects .projectsList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 385px) {
    .projects .projectsList {
      grid-template-columns: 1fr;
    }
  }
